var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-0" },
    [
      _vm.hasOptions
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "right" } },
                [
                  _c(
                    "b-dropdown",
                    {
                      attrs: { right: "", size: "sm", variant: "transparent" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c("b-icon", { attrs: { icon: "gear" } }),
                                _vm._v("\n          Opzioni\n        "),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        222188776
                      ),
                    },
                    [
                      _vm._v(" "),
                      !_vm.hasPermissions
                        ? _c("b-dropdown-item", [
                            _vm._v("Nessun report disponibile"),
                          ])
                        : _c(
                            "div",
                            [
                              _vm.options.filter((e) =>
                                e.formats.includes("csv")
                              )
                                ? _c(
                                    "b-dropdown-group",
                                    {
                                      attrs: {
                                        id: "dropdown-group-1",
                                        header: "Esporta CSV",
                                      },
                                    },
                                    _vm._l(
                                      _vm.options.filter((e) =>
                                        e.formats.includes("csv")
                                      ),
                                      function (option, index) {
                                        return _c(
                                          "span",
                                          { key: index },
                                          [
                                            _vm.hasType("csv", option) &&
                                            _vm.canReport("csv", option)
                                              ? _c(
                                                  "b-dropdown-item-button",
                                                  [
                                                    _vm.hasType(
                                                      "csv",
                                                      option
                                                    ) &&
                                                    _vm.canReport("csv", option)
                                                      ? _c(
                                                          "b-dropdown-item",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handle(
                                                                  "csv",
                                                                  option
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getLabel(
                                                                  "csv",
                                                                  option
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.options.filter((e) =>
                                e.formats.includes("pdf")
                              ).length
                                ? _c(
                                    "b-dropdown-group",
                                    {
                                      attrs: {
                                        id: "dropdown-group-2",
                                        header: "Esporta PDF",
                                      },
                                    },
                                    _vm._l(
                                      _vm.options.filter((e) =>
                                        e.formats.includes("pdf")
                                      ),
                                      function (option, index) {
                                        return _c(
                                          "span",
                                          { key: index },
                                          [
                                            _vm.hasType("pdf", option) &&
                                            _vm.canReport("pdf", option)
                                              ? _c(
                                                  "b-dropdown-item-button",
                                                  [
                                                    _vm.hasType(
                                                      "pdf",
                                                      option
                                                    ) &&
                                                    _vm.canReport("pdf", option)
                                                      ? _c(
                                                          "b-dropdown-item",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handle(
                                                                  "pdf",
                                                                  option
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getLabel(
                                                                  "pdf",
                                                                  option
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("submit-report-modal", {
        attrs: {
          code: _vm.realCode(_vm.optCod),
          beFormCreate: _vm.beFormCreate,
          beRulesCreate: _vm.beRulesCreate,
          allowedFormatOptions: _vm.allwdOpts,
        },
        on: { "save-report": _vm.saveReport },
      }),
      _vm._v(" "),
      _c("select-option-modal", {
        attrs: {
          opts: [
            { text: "Si", value: "Y" },
            { text: "No", value: "N" },
          ],
          title: "Stampa percentuali applicate?",
        },
        on: { select: _vm.onSelectOption },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }