import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { getDictionary } from "@/utils/dictionary";
import { joinValues } from "@/utils/transforms";
import FormMixin from "@/mixins/FormMixin";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [FormMixin],
  data() {
    return {
      fields: [],
      args: {
        options: {
          noPagination: false,
        },
      },
      // exportUrl: null,
    };
  },
  methods: {
    joinValues,
    getDictionary,
    prepareQueryString(tableRef) {
      let queryString;
      let qs = {};
      // 1. filterOn, if any
      if (tableRef.filterOn && Object.keys(tableRef.filterOn).length) {
        let filterOn = tableRef.prepareFilterQueryString(tableRef.filterOn);
        if (filterOn) {
          qs.filterOn = filterOn;
        }
      }
      // 2. filter data, taken from ExportOptions prop exportUrl
      if (this.exportUrl) {
        qs.filter = this.exportUrl;
      }

      if (tableRef.sortBy) {
        if (tableRef.sortBy.indexOf(".") > 0) {
          let arr = tableRef.sortBy.split(".");
          qs.order = `sort[byAttribute]=${arr[1]}`;
        } else {
          qs.order = `sort[byAttribute]=${tableRef.sortBy}`;
        }
        qs.sort = `dir[byAttribute]=${tableRef.sortDesc ? "desc" : "asc"}`;
      }

      queryString = Object.values(qs).join("&");
      return queryString;
    },
    prepareUrl(queryString, urlSuffix, resource = this.resource) {
      let url = urlSuffix ? `api/${resource}/${urlSuffix}` : `api/${resource}`;
      return queryString ? `${url}?${queryString}` : `${url}`;
    },
    onExport(
      format,
      reportCode = "EXPOMON", // CHECK: 'EXPOMON' report code will soon be deleted_at [...]
      requestInput = {},
      silent = false
    ) {
      // let tableRef = this.$refs[this.tableRef];  // before moving here the mixins imports from index
      console.debug(
        "===========================  EXPORTS MIXIN  ========================================"
      );
      console.debug(`this.tableRef=${this.tableRef}`);
      // console.log(`this.$refs[this.tableRef]=${this.$refs[this.tableRef]}`);
      console.debug(
        `this.$parent.$refs[this.$parent.tableRef]=${
          this.$parent.$refs[this.$parent.tableRef]
        }`
      );
      console.debug(
        "===================================================================================="
      );
      let tableRef =
        this.tableRef ||
        // this.$refs[this.tableRef] ||
        this.$parent.$refs[this.$parent.tableRef];

      /* Fix PDF Unico Storico Rendiconti */
      if (tableRef === "BrokerStatementsAccountingTableRef") {
        tableRef = this.$refs[this.tableRef];
      }
      /* Fix PDF Unico Storico Rendiconti */
      if (!tableRef.items.length) {
        if (!silent) {
          this.$showSnackbar({
            preset: "info",
            text: "Non ci sono dati da esportare",
          });
        }
        return;
      }
      let payload;
      let queryString;
      let Repo;
      let report_id;

      // list of 'expomon' alike reports (they don't need a report_id)
      const noIdReports = ["EXPOMON" /* , "ESTRCNT" */];
      // if report code is not inside noIdReports ..
      if (!noIdReports.includes(reportCode)) {
        // .. try to get the id..
        // let rc = reportCode != "RENPROVUNOFFICIALIZED" ? reportCode : "RENPROV";

        let rc =
          typeof this.realCode === "function"
            ? this.realCode(reportCode)
            : reportCode; // defined in ExportOptions
        let found = this.$store.state.auth.reports.find((e) => e.code === rc);
        if (found) {
          report_id = found.id;
        }
        // .. if id is null, reject
        if (!report_id) {
          return new Promise((_, reject) => {
            reject(`Report ${rc} non trovato: impossibile continuare.`);
          });
        }
      }
      // remove byCurrency prop && rename bySector -> byAttribute (ESTRCNT filter only)
      /* eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }] */
      let { byCurrency, ...filterESTCONCO } = this.filter;
      filterESTCONCO["byAttribute"] = filterESTCONCO["bySector"];
      delete filterESTCONCO["bySector"];

      switch (reportCode) {
        case "TOTAFOCA":
        case "TOTASOSP":
        case "REGIFOCA":
        case "ESTCONAG":
          queryString = this.prepareQueryString(tableRef);
          payload = {
            report_id,
            url: this.prepareUrl(queryString),
            request_input: requestInput,
            format: format,
          };
          Repo = RepositoryFactory.get("report");
          return Repo.report(reportCode, payload)
            .then(() => {
              this.$showSnackbar({
                preset: "info",
                text: `Richiesta accettata: report in elaborazione`,
              });
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        case "AGRENPROV":
          payload = {
            format,
            report_id,
            request_input: {
              rendiconti: this.uniquePdfIds,
            },
          };
          Repo = RepositoryFactory.get("report");
          return Repo.report(reportCode, payload)
            .then(() => {
              this.$showSnackbar({
                preset: "info",
                text: `Richiesta accettata: report in elaborazione`,
              });
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        case "ESTCONCO":
          queryString = `${tableRef.prepareFilterQueryString(filterESTCONCO)}`; //.replace("bySector", "byAttribute");
          payload = {
            format: format,
            url: this.prepareUrl(queryString, null, "sectors"),
          };
          Repo = RepositoryFactory.get("report");
          return Repo.report(reportCode, payload)
            .then(() => {
              if (!silent) {
                this.$showSnackbar({
                  preset: "info",
                  text: `Richiesta accettata: report in elaborazione`,
                });
              }
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        case "RENCOMPROV":
          queryString = this.prepareQueryString(tableRef);
          payload = {
            format,
            report_id,
            url: this.prepareUrl(queryString),
          };
          Repo = RepositoryFactory.get("report");
          return Repo.report(reportCode, payload)
            .then(() => {
              if (!silent) {
                this.$showSnackbar({
                  preset: "info",
                  text: `Richiesta accettata: report in elaborazione`,
                });
              }
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        case "RENPROV":
          queryString = `${tableRef.prepareFilterQueryString(
            this.filter
          )}&byAttribute[id]=${tableRef.selectedRows.join(",")}`;
          payload = {
            format,
            report_id,
            show_percentages: requestInput.show_percentages,
            url: this.prepareUrl(queryString),
            byCalendar: `${this.filter.byCalendar.from},${this.filter.byCalendar.to}`,
            insurer_accounting: "N",
          };
          Repo = RepositoryFactory.get("book_entry");
          return Repo.report_rendiconto(queryString, payload)
            .then(() => {
              if (!silent) {
                this.$showSnackbar({
                  preset: "info",
                  text: `Richiesta accettata: report in elaborazione`,
                });
              }
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        case "RENPROVUNOFFICIALIZED":
          // api/book_entries?byTotals[id]=1&byAttribute[id]=29,28,30
          // NOTE: RENPROV prima di UFFICIALIZZA
          // queryString = `byTotals[id]=${
          //   this.salesman_id
          // }&byAttribute[id]=${tableRef.selectedRows.join(",")}`;
          queryString = `byTotals[id]=${
            requestInput.salesman_id
          }&byAttribute[id]=${tableRef.selectedRows.join(",")}`;
          payload = {
            format,
            report_id,
            show_percentages: requestInput.show_percentages,
            url: this.prepareUrl(queryString, "report", this.resource),
            byCalendar: `${this.filter.byCalendar.from},${this.filter.byCalendar.to}`,
            insurer_accounting: "N",
          };
          Repo = RepositoryFactory.get("book_entry");
          return Repo.report_rendiconto(null, payload)
            .then(() => {
              if (!silent) {
                this.$showSnackbar({
                  preset: "info",
                  text: `Richiesta accettata: report in elaborazione`,
                });
              }
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        // case "STATSCONT":
        //   payload = {
        //     format,
        //     report_id,
        //     request_input: {
        //       rendiconti: this.uniquePdfIds,
        //     },
        //   };
        //   Repo = RepositoryFactory.get("report");
        //   return Repo.report(reportCode, payload)
        //     .then(() => {
        //       this.$showSnackbar({
        //         preset: "info",
        //         text: `Richiesta accettata: report in elaborazione`,
        //       });
        //     })
        //     .catch((error) => {
        //       let errMsg = this.$getErrorMessage(error);
        //       this.$showSnackbar({
        //         preset: "error",
        //         text: `${errMsg}`,
        //       });
        //     });
        default:
          // EXPOMON
          queryString = this.prepareQueryString(tableRef);
          payload = {
            format: format,
            url: this.prepareUrl(queryString),
          };
          Repo = RepositoryFactory.get("report");
          return Repo.report(reportCode, payload)
            .then(() => {
              this.$showSnackbar({
                preset: "info",
                text: `Richiesta accettata: report in elaborazione`,
              });
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
      }
    },
  },
};
